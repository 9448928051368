@tailwind base;
@tailwind components;
@tailwind utilities;



.clip-hero-image {
  clip-path: inset(0% 0 15% 0);
  object-position: center 50%;
}


/* @font-face {
  font-family: 'OC Highway VAR.2';
  src: url('../public/fonts/OCHighwayVAR2-Series5.woff2') format('woff2-variations');
  font-weight: 100 900;
  font-stretch: 75% 125%;
  font-style: normal;
}

body {
  font-family: 'OC Highway VAR.2', sans-serif;
}

/* Optional: Apply the font to specific elements if needed */
/* h1, h2, h3, h4, h5, h6, p, span, div {
  font-family: 'OC Highway VAR.2', sans-serif;
}
 */


.object-center-bottom {
  object-position: center 80%;
}



/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
